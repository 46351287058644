import styled, { css, extensionsHook } from '../styled-components';
const headerFontSize = {
  1: '1.85714em',
  2: '1.57143em',
  3: '1.27em'
};
export const headerCommonMixin = level => css(["font-family:", ";font-weight:400;font-size:", ";"], props => props.theme.typography.headings.fontFamily, headerFontSize[level]);
export const H1 = styled.h1.withConfig({
  componentId: "shv3r-0"
})(["", ";color:", ";", ";"], headerCommonMixin(1), props => props.theme.colors.primary.main, extensionsHook('H1'));
export const H2 = styled.h2.withConfig({
  componentId: "shv3r-1"
})(["", ";color:black;", ";"], headerCommonMixin(2), extensionsHook('H2'));
export const H3 = styled.h2.withConfig({
  componentId: "shv3r-2"
})(["", ";color:black;", ";"], headerCommonMixin(3), extensionsHook('H3'));
export const RightPanelHeader = styled.h3.withConfig({
  componentId: "shv3r-3"
})(["color:", ";", ";"], ({
  theme
}) => theme.rightPanel.textColor, extensionsHook('RightPanelHeader'));
export const UnderlinedHeader = styled.h5.withConfig({
  componentId: "shv3r-4"
})(["border-bottom:1px solid rgba(38,50,56,0.3);margin:1em 0 1em 0;color:rgba(38,50,56,0.5);font-weight:normal;text-transform:uppercase;font-size:0.929em;line-height:20px;", ";"], extensionsHook('UnderlinedHeader'));